import React from "react";
import { Checkbox, TableCell, TableRow } from "@mui/material";

import Body1 from "../../typography/body-01";

import styles from "../index.module.scss";

const AdminUserRow = ({ row, index, selectedBroker, handleSelectBroker }) => {
	const labelId = `enhanced-table-checkbox-${row.id}`;

	return (
		<TableRow
			hover
			tabIndex={-1}
			key={row.id}
			onClick={() => handleSelectBroker(index)}
			role="checkbox"
			selected={selectedBroker === index}
			className={selectedBroker === index ? styles.highlightRow : ""}
		>
			<TableCell padding="checkbox">
				<Checkbox
					color="primary"
					checked={selectedBroker === index}
					inputProps={{
						"aria-labelledby": labelId,
					}}
				/>
			</TableCell>
			<TableCell>
				<Body1
					text={`${row?.user?.first_name || ""} ${
						row?.user?.middle_name || ""
					} ${row?.user?.last_name || ""}`}
				/>
			</TableCell>
			<TableCell>
				<Body1 text={row?.nmls_id} />
			</TableCell>
			<TableCell>
				<Body1 text={row?.organization?.name} />
			</TableCell>
			<TableCell>
				<Body1 text={row?.user?.email} />
			</TableCell>
			<TableCell>
				<Body1 text={row?.phone} />
			</TableCell>
			<TableCell>
				<Body1 extraClass={styles.capitalize} text={row?.access_level} />
			</TableCell>
			<TableCell>
				<Body1
					extraClass={styles.capitalize}
					text={
						row?.login_enabled === true
							? "Yes"
							: row?.login_enabled === false
							? "No"
							: "-"
					}
				/>
			</TableCell>
		</TableRow>
	);
};

export default AdminUserRow;
