import React from "react";
import { DateTime } from "luxon";
import { useSelector } from "react-redux";

// mui components
import { IconButton, Stack, TableCell, TableRow } from "@mui/material";

// custom components
import Body2 from "../../typography/body-02";

// reducer slice
import { selectUserDetails } from "../../../features/auth/authSlice";

// mui icons
import {
	DeleteOutlined,
	DownloadOutlined,
	EditOutlined,
	FilePresentOutlined,
} from "@mui/icons-material";

// custom styles
import styles from "../index.module.scss";
const ResourceFileRow = ({
	row,
	index,
	handleFileRename,
	handleFileDelete,
}) => {
	const userDetails = useSelector(selectUserDetails);

	return (
		<TableRow hover tabIndex={-1} key={row.id}>
			{/* file name */}
			<TableCell>
				<Stack direction="row" className={styles.fileNameContainer}>
					<FilePresentOutlined className={styles.fileIcon} />
					<Body2 text={row?.file_name} />
				</Stack>
			</TableCell>

			{/* file type */}
			<TableCell>
				<Body2 text="File" />
			</TableCell>

			{/* file created date */}
			<TableCell>
				<Body2
					text={DateTime.fromISO(row?.created_date, {
						zone: "America/New_York",
					}).toFormat("MM/dd/yyyy")}
				/>
			</TableCell>

			{/* folder actions */}
			<TableCell>
				<Stack direction="row" className={styles.actionsContainer}>
					<IconButton
						className={styles.iconBtn}
						onClick={() => window.open(row?.file_url, "_blank")}
					>
						<DownloadOutlined className={styles.downloadIcon} />
					</IconButton>

					{userDetails?.user_roles?.includes("admin") && (
						<IconButton
							className={styles.iconBtn}
							onClick={() => handleFileRename(row?.file_name, row?.id, index)}
						>
							<EditOutlined className={styles.editIcon} />
						</IconButton>
					)}

					{userDetails?.user_roles?.includes("admin") && (
						<IconButton
							className={styles.iconBtn}
							onClick={() => handleFileDelete(row?.file_name, row?.id, index)}
						>
							<DeleteOutlined className={styles.deleteIcon} />
						</IconButton>
					)}
				</Stack>
			</TableCell>
		</TableRow>
	);
};

export default ResourceFileRow;
