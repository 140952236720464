import React, { useState } from "react";
import {
	Box,
	Stack,
	TableCell,
	TableHead,
	TableRow,
	TableSortLabel,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";

// helper functions
import { sortAmount, sortDate } from "../../../utils/sort-methods";

// custom components
import OutlinedTextInput from "../../custom-input/outlined-text-input";
import Heading4 from "../../typography/heading-04";

// custom styles
import styles from "./index.module.scss";
import Heading5 from "../../typography/heading-05";

const TableHeadResourceFile = (props) => {
	const {
		data,
		rowData,
		order,
		orderBy,
		onRequestSort,
		filteredLoans,
		setFilteredLoans,
	} = props;

	const [searchValue, setSearchValue] = useState({
		file_name: "",
	});

	const createSortHandler = (property) => (event) => {
		let fieldId;

		if (property === "upload-date") {
			fieldId = "created_date";
		} else if (property === "name") {
			fieldId = "file_name";
		}

		let sortedData;

		if (property.includes("date")) {
			sortedData = sortDate(filteredLoans, order, fieldId);
		} else {
			sortedData = sortAmount(filteredLoans, order, fieldId);
		}

		console.log("70 sorted data:", sortedData);
		setFilteredLoans(sortedData);
		onRequestSort(event, property);
	};

	const handleSearchTable = (value, name) => {
		console.log("value:", value);
		console.log("name:", name);

		let updatedSearchValue = { ...searchValue, [name]: value };
		console.log("80 updated values:", updatedSearchValue);
		setSearchValue(updatedSearchValue);

		let searchedLoans = [];

		console.log("105 row data:", rowData);

		searchedLoans = rowData.filter((item) =>
			item?.file_name
				?.toLowerCase()
				?.includes(updatedSearchValue?.file_name?.toLowerCase())
		);

		console.log("105 searched loans:", searchedLoans);
		setFilteredLoans(searchedLoans);
	};

	return (
		<TableHead>
			<TableRow>
				{data.map((headCell) => (
					<TableCell
						key={headCell.id}
						align={headCell.align}
						padding={headCell.disablePadding ? "none" : "normal"}
						sortDirection={orderBy === headCell.id ? order : false}
						style={{
							minWidth: headCell.minWidth,
							verticalAlign: headCell.label === "Remedy" && "text-top",
						}}
					>
						<Stack direction="column" className={styles.headerStack}>
							{headCell.label === "" || headCell.label === "Remedy" ? (
								<Heading4
									text={headCell.label}
									extraClass={styles.thText}
									fontType="semibold"
								/>
							) : (
								<TableSortLabel
									active={orderBy === headCell.id}
									direction={orderBy === headCell.id ? order : "asc"}
									onClick={createSortHandler(headCell.id)}
									className={styles.tableSearchLabel}
								>
									<Heading5
										text={headCell.label}
										extraClass={styles.thText}
										fontType="semibold"
									/>
									{orderBy === headCell.id ? (
										<Box component="span" sx={visuallyHidden}>
											{order === "desc"
												? "sorted descending"
												: "sorted ascending"}
										</Box>
									) : null}
								</TableSortLabel>
							)}

							{headCell.label === "Name" && (
								<OutlinedTextInput
									extraClass={styles.inputField}
									variant="outlined"
									placeholder="Search here..."
									name={headCell.label === "Name" && "file_name"}
									value={headCell.label === "Name" ? searchValue?.title : ""}
									handleChange={(event) =>
										headCell.label === "Name" &&
										handleSearchTable(
											event.target.value,
											event.target.name,
											"file_name"
										)
									}
								/>
							)}
						</Stack>
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
};

export default TableHeadResourceFile;
