import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { DateTime } from "luxon";

// mui components
import { Box, Stack } from "@mui/material";

// mui icons
import CloseIcon from "@mui/icons-material/Close";

// custom components
import TertiaryButton from "../../buttons/tertiary-button";
import CustomDivider from "../../custom-divider";
import Heading3 from "../../typography/heading-03";
import Heading4 from "../../typography/heading-04";
import Body1 from "../../typography/body-01";

// helper functions
import { filterValue } from "../../../utils/filter-value";

// reducer slice
import { selectLockRequestLog } from "../../../features/lock-request-log/lockRequestLogSlice";

// api slices
import { useFieldMutation } from "../../../features/field-values/fieldValueApiSlice";

// custom styles
import styles from "./index.module.scss";

const ImportantDatesCard = ({
	handleTabChange,
	handleOpenLoadingDataModal,
	handleCloseLoadingDataModal,
	setLoadingDataText,
	handleOpenErrorModal,
	setErrorModalText,
}) => {
	const params = useParams();

	const [field] = useFieldMutation();

	const lockRequestLogData = useSelector(selectLockRequestLog);
	console.log("50 lockRequestLogData:", lockRequestLogData);

	const [fieldData, setFieldData] = useState([]);

	const handleErrorModal = (data) => {
		console.log("60 data:", data);

		handleCloseLoadingDataModal();
		handleOpenErrorModal();
		setErrorModalText("There was an error getting important dates");
	};

	const getFieldValues = async () => {
		console.log("75 loan id:", params?.id);
		handleOpenLoadingDataModal();

		try {
			const getValues = await field({
				loan_guid: params?.id,
				fields:
					"3142,3983,3197,3145,3147,3152,3153,3154,3155,3977,3978,3979,3980,3624,3857,CX.UW.DOC.APP.EXPIRES,CX.UW.DOC.CREDIT.EXPIRES,CX.UW.DOC.INCOME.EXPIRES,CX.UW.DOC.ASSETS.EXPIRES,CX.UW.DOC.TITLE.EXPIRES,CX.UW.DOC.SECOND.APP.EXPIRES,CX.UW.DOC.VOE.EXPIRES,CX.CLOSING.DOCS.SENT.DATE,CX.REQUESTED.CLOSING.DATE,1996,1997,1999",
				//  TODO prashant; LOGAN-933
				// fields:
				// 	"3142,3983,3197,3145,3147,3152,3153,3154,3155,3977,3978,3979,3980,3624,3857,CX.UW.DOC.APP.EXPIRES,CX.UW.DOC.CREDIT.EXPIRES,CX.UW.DOC.INCOME.EXPIRES,CX.UW.DOC.ASSETS.EXPIRES,CX.UW.DOC.TITLE.EXPIRES,CX.UW.DOC.SECOND.APP.EXPIRES,CX.UW.DOC.VOE.EXPIRES,CX.CLOSING.DOCS.SENT.DATE,CX.REQUESTED.CLOSING.DATE,1996,1997,1999,CX.UW.DOC.PAYOFF.DATE,CX.UW.DOC.PAYOFF.THROUGH,CX.UW.DOC.CUSTOM1.NAME,CX.UW.DOC.CUSTOM2.NAME,CX.UW.DOC.CUSTOM1.EXPIRES,CX.UW.DOC.CUSTOM2.EXPIRES",
			}).unwrap();

			console.log("75 all fields:", getValues?.response);

			if (getValues?.response?.length > 0) {
				setFieldData(getValues?.response);
				handleCloseLoadingDataModal();
				setLoadingDataText("Setting up the lock info");
			}
		} catch (err) {
			console.log("270 error:", err);
			handleErrorModal(err);
		}
	};

	useEffect(() => {
		getFieldValues();
	}, []);

	return (
		<Stack direction="column" className={styles.stackContainer}>
			<Stack
				direction="row"
				alignItems="center"
				justifyContent="space-between"
				className={styles.topContainer}
			>
				<Heading3
					text="Important Dates"
					extraClass={styles.tabTitle}
					fontType="semibold"
				/>

				<Stack
					direction="row"
					alignItems="center"
					justifyContent="flex-end"
					className={styles.topBtnContainer}
				>
					<TertiaryButton
						text="Close this view"
						startIcon={<CloseIcon />}
						onClick={(e) => handleTabChange(e, false)}
					/>
				</Stack>
			</Stack>

			<Stack direction="row" className={styles.bottomContainer}>
				<Stack direction="column" className={styles.infoContainer}>
					<Heading4 text="Application" fontType="semibold" />

					{/* application date */}
					<Stack
						direction="row"
						alignItems="center"
						className={styles.infoDetailContainer}
					>
						<Box className={styles.infoDetailTitle}>
							<Body1 text="Application Date" extraClass={styles.labelText} />
						</Box>
						<Box className={styles.infoDetailValue}>
							<Body1
								text={filterValue(fieldData, "3142") || "//"}
								extraClass={styles.valueText}
							/>
						</Box>
					</Stack>

					{/* eConsent date */}
					<Stack
						direction="row"
						alignItems="center"
						className={styles.infoDetailContainer}
					>
						<Box className={styles.infoDetailTitle}>
							{/* TODO prashant; LOGAN-930 */}
							<Body1 text="eConsent Date" extraClass={styles.labelText} />
							{/* <Body1
								text="Received Borrower Consent to Electronic Disclosures"
								extraClass={styles.labelText}
							/> */}
						</Box>
						<Box className={styles.infoDetailValue}>
							<Body1
								text={filterValue(fieldData, "3983") || "//"}
								extraClass={styles.valueText}
							/>
						</Box>
					</Stack>

					{/* intent to Proceed */}
					<Stack
						direction="row"
						alignItems="center"
						className={styles.infoDetailContainer}
					>
						<Box className={styles.infoDetailTitle}>
							{/* TODO prashant; LOGAN-930 */}
							<Body1 text="Intent to Proceed" extraClass={styles.labelText} />
							{/* <Body1
								text="Intent to Proceed Signed by Borrower"
								extraClass={styles.labelText}
							/> */}
						</Box>
						<Box className={styles.infoDetailValue}>
							<Body1
								text={filterValue(fieldData, "3197") || "//"}
								extraClass={styles.valueText}
							/>
						</Box>
					</Stack>

					{/* earliest Fee collection */}
					<Stack
						direction="row"
						alignItems="center"
						className={styles.infoDetailContainer}
					>
						<Box className={styles.infoDetailTitle}>
							<Body1
								text="Earliest Fee Collection"
								extraClass={styles.labelText}
							/>
						</Box>
						<Box className={styles.infoDetailValue}>
							<Body1
								text={filterValue(fieldData, "3145") || "//"}
								extraClass={styles.valueText}
							/>
						</Box>
					</Stack>

					{/* earliest Closing */}
					<Stack
						direction="row"
						alignItems="center"
						className={styles.infoDetailContainer}
					>
						<Box className={styles.infoDetailTitle}>
							<Body1 text="Earliest Closing" extraClass={styles.labelText} />
						</Box>
						<Box className={styles.infoDetailValue}>
							<Body1
								text={filterValue(fieldData, "3147") || "//"}
								extraClass={styles.valueText}
							/>
						</Box>
					</Stack>

					<CustomDivider extraClass={styles.sectionHeaderLine} />
					<Heading4 text="Loan Estimate" fontType="semibold" />

					{/* initial LE Sent */}
					<Stack
						direction="row"
						alignItems="center"
						className={styles.infoDetailContainer}
					>
						<Box className={styles.infoDetailTitle}>
							<Body1 text="Initial LE Sent" extraClass={styles.labelText} />
						</Box>
						<Box className={styles.infoDetailValue}>
							<Body1
								text={filterValue(fieldData, "3152") || "//"}
								extraClass={styles.valueText}
							/>
						</Box>
					</Stack>

					{/* initial LE Received */}
					<Stack
						direction="row"
						alignItems="center"
						className={styles.infoDetailContainer}
					>
						<Box className={styles.infoDetailTitle}>
							{/* TODO prashant; LOGAN-930 */}
							<Body1 text="Initial LE Received" extraClass={styles.labelText} />
							{/* <Body1
								text="Initial LE Received or Acknowledged by Borrower"
								extraClass={styles.labelText}
							/> */}
						</Box>
						<Box className={styles.infoDetailValue}>
							<Body1
								text={filterValue(fieldData, "3153") || "//"}
								extraClass={styles.valueText}
							/>
						</Box>
					</Stack>

					{/* revised LE Sent */}
					<Stack
						direction="row"
						alignItems="center"
						className={styles.infoDetailContainer}
					>
						<Box className={styles.infoDetailTitle}>
							<Body1 text="Revised LE Sent" extraClass={styles.labelText} />
						</Box>
						<Box className={styles.infoDetailValue}>
							<Body1
								text={filterValue(fieldData, "3154") || "//"}
								extraClass={styles.valueText}
							/>
						</Box>
					</Stack>

					{/* revised LE Received */}
					<Stack
						direction="row"
						alignItems="center"
						className={styles.infoDetailContainer}
					>
						<Box className={styles.infoDetailTitle}>
							{/* TODO prashant; LOGAN-930 */}
							<Body1 text="Revised LE Received" extraClass={styles.labelText} />
							{/* <Body1
								text="Revised LE Received or Acknowledged by Borrower"
								extraClass={styles.labelText}
							/> */}
						</Box>

						<Box className={styles.infoDetailValue}>
							<Body1
								text={filterValue(fieldData, "3155") || "//"}
								extraClass={styles.valueText}
							/>
						</Box>
					</Stack>

					<CustomDivider extraClass={styles.sectionHeaderLine} />
					<Heading4 text="Closing Disclosure" fontType="semibold" />

					{/* initial CD Sent */}
					<Stack
						direction="row"
						alignItems="center"
						className={styles.infoDetailContainer}
					>
						<Box className={styles.infoDetailTitle}>
							<Body1 text="Initial CD Sent" extraClass={styles.labelText} />
						</Box>
						<Box className={styles.infoDetailValue}>
							<Body1
								text={filterValue(fieldData, "3977") || "//"}
								extraClass={styles.valueText}
							/>
						</Box>
					</Stack>

					{/* initial CD Signed */}
					<Stack
						direction="row"
						alignItems="center"
						className={styles.infoDetailContainer}
					>
						<Box className={styles.infoDetailTitle}>
							{/* TODO prashant; LOGAN-930 */}
							<Body1 text="Initial CD Received" extraClass={styles.labelText} />
							{/* <Body1 text="Initial CD Signed" extraClass={styles.labelText} /> */}
						</Box>
						<Box className={styles.infoDetailValue}>
							<Body1
								text={filterValue(fieldData, "3978") || "//"}
								extraClass={styles.valueText}
							/>
						</Box>
					</Stack>

					{/* revised CD Sent */}
					<Stack
						direction="row"
						alignItems="center"
						className={styles.infoDetailContainer}
					>
						<Box className={styles.infoDetailTitle}>
							<Body1 text="Revised CD Sent" extraClass={styles.labelText} />
						</Box>
						<Box className={styles.infoDetailValue}>
							<Body1
								text={filterValue(fieldData, "3979") || "//"}
								extraClass={styles.valueText}
							/>
						</Box>
					</Stack>

					{/* revised CD Signed */}
					<Stack
						direction="row"
						alignItems="center"
						className={styles.infoDetailContainer}
					>
						<Box className={styles.infoDetailTitle}>
							{/* TODO prashant; LOGAN-930 */}
							<Body1 text="Revised CD Received" extraClass={styles.labelText} />
							{/* <Body1 text="Revised CD Signed" extraClass={styles.labelText} /> */}
						</Box>
						<Box className={styles.infoDetailValue}>
							<Body1
								text={filterValue(fieldData, "3980") || "//"}
								extraClass={styles.valueText}
							/>
						</Box>
					</Stack>
				</Stack>

				<Stack direction="column" className={styles.infoContainer}>
					<Heading4 text="Appraisal" fontType="semibold" />

					{/* appraisal Sent */}
					<Stack
						direction="row"
						alignItems="center"
						className={styles.infoDetailContainer}
					>
						<Box className={styles.infoDetailTitle}>
							<Body1 text="Appraisal Sent" extraClass={styles.labelText} />
						</Box>
						<Box className={styles.infoDetailValue}>
							<Body1
								text={filterValue(fieldData, "3624") || "//"}
								extraClass={styles.valueText}
							/>
						</Box>
					</Stack>

					{/* Revised appraisal Sent */}
					<Stack
						direction="row"
						alignItems="center"
						className={styles.infoDetailContainer}
					>
						<Box className={styles.infoDetailTitle}>
							<Body1
								text="Revised Appraisal Sent"
								extraClass={styles.labelText}
							/>
						</Box>
						<Box className={styles.infoDetailValue}>
							<Body1
								text={filterValue(fieldData, "3857") || "//"}
								extraClass={styles.valueText}
							/>
						</Box>
					</Stack>

					<CustomDivider extraClass={styles.sectionHeaderLine} />

					{/* Document Expiration section */}
					<Heading4 text="Document Expiration" fontType="semibold" />

					{/* appraisal */}
					<Stack
						direction="row"
						alignItems="center"
						className={styles.infoDetailContainer}
					>
						<Box className={styles.infoDetailTitle}>
							<Body1 text="Appraisal" extraClass={styles.labelText} />
						</Box>
						<Box className={styles.infoDetailValue}>
							<Body1
								text={filterValue(fieldData, "CX.UW.DOC.APP.EXPIRES") || "//"}
								extraClass={styles.valueText}
							/>
						</Box>
					</Stack>

					{/* credit Report */}
					<Stack
						direction="row"
						alignItems="center"
						className={styles.infoDetailContainer}
					>
						<Box className={styles.infoDetailTitle}>
							<Body1 text="Credit Report" extraClass={styles.labelText} />
						</Box>
						<Box className={styles.infoDetailValue}>
							<Body1
								text={
									filterValue(fieldData, "CX.UW.DOC.CREDIT.EXPIRES") || "//"
								}
								extraClass={styles.valueText}
							/>
						</Box>
					</Stack>

					{/* income Docs */}
					<Stack
						direction="row"
						alignItems="center"
						className={styles.infoDetailContainer}
					>
						<Box className={styles.infoDetailTitle}>
							<Body1 text="Income Docs" extraClass={styles.labelText} />
						</Box>
						<Box className={styles.infoDetailValue}>
							<Body1
								text={
									filterValue(fieldData, "CX.UW.DOC.INCOME.EXPIRES") || "//"
								}
								extraClass={styles.valueText}
							/>
						</Box>
					</Stack>

					{/* asset Docs */}
					<Stack
						direction="row"
						alignItems="center"
						className={styles.infoDetailContainer}
					>
						<Box className={styles.infoDetailTitle}>
							<Body1 text="Asset Docs" extraClass={styles.labelText} />
						</Box>
						<Box className={styles.infoDetailValue}>
							<Body1
								text={
									filterValue(fieldData, "CX.UW.DOC.ASSETS.EXPIRES") || "//"
								}
								extraClass={styles.valueText}
							/>
						</Box>
					</Stack>

					{/* title Report */}
					<Stack
						direction="row"
						alignItems="center"
						className={styles.infoDetailContainer}
					>
						<Box className={styles.infoDetailTitle}>
							<Body1 text="Title Report" extraClass={styles.labelText} />
						</Box>
						<Box className={styles.infoDetailValue}>
							<Body1
								text={filterValue(fieldData, "CX.UW.DOC.TITLE.EXPIRES") || "//"}
								extraClass={styles.valueText}
							/>
						</Box>
					</Stack>

					{/* secondary appraisal */}
					<Stack
						direction="row"
						alignItems="center"
						className={styles.infoDetailContainer}
					>
						<Box className={styles.infoDetailTitle}>
							<Body1 text="Secondary Appraisal" extraClass={styles.labelText} />
						</Box>
						<Box className={styles.infoDetailValue}>
							<Body1
								text={
									filterValue(fieldData, "CX.UW.DOC.SECOND.APP.EXPIRES") || "//"
								}
								extraClass={styles.valueText}
							/>
						</Box>
					</Stack>

					{/* verbal VOE */}
					<Stack
						direction="row"
						alignItems="center"
						className={styles.infoDetailContainer}
					>
						<Box className={styles.infoDetailTitle}>
							<Body1 text="Verbal VOE" extraClass={styles.labelText} />
						</Box>
						<Box className={styles.infoDetailValue}>
							<Body1
								text={filterValue(fieldData, "CX.UW.DOC.VOE.EXPIRES") || "//"}
								extraClass={styles.valueText}
							/>
						</Box>
					</Stack>

					{/*  TODO prashant; LOGAN-933 */}

					{/* CX.UW.DOC.CUSTOM1.NAME */}
					{/* {filterValue(fieldData, "CX.UW.DOC.CUSTOM1.NAME") && (
						<Stack
							direction="row"
							alignItems="center"
							className={styles.infoDetailContainer}
						>
							<Box className={styles.infoDetailTitle}>
								<Body1
									text={filterValue(fieldData, "CX.UW.DOC.CUSTOM1.NAME")}
									extraClass={styles.labelText}
								/>
							</Box>
							<Box className={styles.infoDetailValue}>
								<Body1
									text={
										filterValue(fieldData, "CX.UW.DOC.CUSTOM1.EXPIRES") || "//"
									}
									extraClass={styles.valueText}
								/>
							</Box>
						</Stack>
					)} */}

					{/* CX.UW.DOC.CUSTOM2.NAME */}
					{/* {filterValue(fieldData, "CX.UW.DOC.CUSTOM2.NAME") && (
						<Stack
							direction="row"
							alignItems="center"
							className={styles.infoDetailContainer}
						>
							<Box className={styles.infoDetailTitle}>
								<Body1
									text={filterValue(fieldData, "CX.UW.DOC.CUSTOM2.NAME")}
									extraClass={styles.labelText}
								/>
							</Box>
							<Box className={styles.infoDetailValue}>
								<Body1
									text={
										filterValue(fieldData, "CX.UW.DOC.CUSTOM2.EXPIRES") || "//"
									}
									extraClass={styles.valueText}
								/>
							</Box>
						</Stack>
					)} */}

					<CustomDivider extraClass={styles.sectionHeaderLine} />

					{/* Closing section */}
					<Heading4 text="Closing" fontType="semibold" />

					{/* closing Docs Sent */}
					<Stack
						direction="row"
						alignItems="center"
						className={styles.infoDetailContainer}
					>
						<Box className={styles.infoDetailTitle}>
							<Body1 text="Closing Docs Sent" extraClass={styles.labelText} />
						</Box>
						<Box className={styles.infoDetailValue}>
							<Body1
								text={
									filterValue(fieldData, "CX.CLOSING.DOCS.SENT.DATE")
										? DateTime.fromJSDate(
												new Date(
													filterValue(fieldData, "CX.CLOSING.DOCS.SENT.DATE")
												)
										  )?.toFormat("L/d/yyyy")
										: "//"
								}
								extraClass={styles.valueText}
							/>
						</Box>
					</Stack>

					{/* Requested closing */}
					<Stack
						direction="row"
						alignItems="center"
						className={styles.infoDetailContainer}
					>
						<Box className={styles.infoDetailTitle}>
							<Body1 text="Requested Closing" extraClass={styles.labelText} />
						</Box>
						<Box className={styles.infoDetailValue}>
							<Body1
								text={
									filterValue(fieldData, "CX.REQUESTED.CLOSING.DATE") || "//"
								}
								extraClass={styles.valueText}
							/>
						</Box>
					</Stack>

					{/* wire Ordered */}
					<Stack
						direction="row"
						alignItems="center"
						className={styles.infoDetailContainer}
					>
						<Box className={styles.infoDetailTitle}>
							<Body1 text="Wire Ordered" extraClass={styles.labelText} />
						</Box>
						<Box className={styles.infoDetailValue}>
							<Body1
								text={filterValue(fieldData, "1996") || "//"}
								extraClass={styles.valueText}
							/>
						</Box>
					</Stack>

					{/* wire Sent to Settlement Agent */}
					<Stack
						direction="row"
						alignItems="center"
						className={styles.infoDetailContainer}
					>
						<Box className={styles.infoDetailTitle}>
							<Body1
								text="Wire Sent to Settlement Agent"
								extraClass={styles.labelText}
							/>
						</Box>
						<Box className={styles.infoDetailValue}>
							<Body1
								text={filterValue(fieldData, "1997") || "//"}
								extraClass={styles.valueText}
							/>
						</Box>
					</Stack>

					{/* authorized to Disburse */}
					<Stack
						direction="row"
						alignItems="center"
						className={styles.infoDetailContainer}
					>
						<Box className={styles.infoDetailTitle}>
							<Body1
								text="Authorized to Disburse"
								extraClass={styles.labelText}
							/>
						</Box>
						<Box className={styles.infoDetailValue}>
							<Body1
								text={filterValue(fieldData, "1999") || "//"}
								extraClass={styles.valueText}
							/>
						</Box>
					</Stack>

					{/*  TODO prashant; LOGAN-933 */}

					{/* Payoff Date Obtained */}
					{/* <Stack
						direction="row"
						alignItems="center"
						className={styles.infoDetailContainer}
					>
						<Box className={styles.infoDetailTitle}>
							<Body1
								text="Payoff Date Obtained"
								extraClass={styles.labelText}
							/>
						</Box>
						<Box className={styles.infoDetailValue}>
							<Body1
								text={filterValue(fieldData, "CX.UW.DOC.PAYOFF.DATE") || "//"}
								extraClass={styles.valueText}
							/>
						</Box>
					</Stack> */}

					{/* Payoff Good Through */}
					{/* <Stack
						direction="row"
						alignItems="center"
						className={styles.infoDetailContainer}
					>
						<Box className={styles.infoDetailTitle}>
							<Body1 text="Payoff Good Through" extraClass={styles.labelText} />
						</Box>
						<Box className={styles.infoDetailValue}>
							<Body1
								text={
									filterValue(fieldData, "CX.UW.DOC.PAYOFF.THROUGH") || "//"
								}
								extraClass={styles.valueText}
							/>
						</Box>
					</Stack> */}
				</Stack>
			</Stack>
		</Stack>
	);
};

export default ImportantDatesCard;
