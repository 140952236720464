import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

// mui components
import { Box, InputLabel, Stack } from "@mui/material";

// mui icons
import {
	PersonAddAltOutlined,
	PersonRemoveOutlined,
	SearchOutlined,
	SyncOutlined,
	FilterAlt,
} from "@mui/icons-material";

// api slice
import { useGetBrokerListMutation } from "../../features/broker-list/brokerListApiSlice";
import { useCheckUserMutation } from "../../features/admin/check-user/checkUserApiSlice";
import { useResetPasswordMutation } from "../../features/admin/reset-password/resetPasswordApiSlice";
import { useImportTpoUserMutation } from "../../features/admin/import-tpo/importTpoApiSlice";
import { useResetOrganizationUsersPasswordMutation } from "../../features/admin/reset-organization-users-password/reset-organization-users-password-api-slice";

// reducer slice
import { setBrokerListReducer } from "../../features/broker-list/brokerListSlice";
import { setImpersonatedUserReducer } from "../../features/admin/impersonated-user/impersonatedUserSlice";
import { setOrganizationName } from "../../features/auth/authSlice";

// custom components
import CustomLayout from "../../components/layout";
import CustomTable from "../../components/custom-table";
import TemporaryDrawer from "../../components/temporary-drawer";
import OutlinedTextInput from "../../components/custom-input/outlined-text-input";
import PrimaryButton from "../../components/buttons/primary-button";
import SecondaryButton from "../../components/buttons/secondary-button";
import Heading4 from "../../components/typography/heading-04";
import Heading6 from "../../components/typography/heading-06";
import Body3 from "../../components/typography/body-03";
import Heading5 from "../../components/typography/heading-05";
import SecondarySelect from "../../components/custom-select/secondary-select";

// helper utils
import { loanChannelOptions } from "../../utils/select-options";

// custom modal
import LoaderModal from "../../components/modal/loader-modal";
import ProcessingModal from "../../components/modal/processing-modal";
import UpdatePasswordModal from "../../components/modal/update-password-modal";
import ConfirmationModal from "../../components/modal/confirmation-modal";
import SuccessModal from "../../components/modal/success-modal";
import ErrorModal from "../../components/modal/error-modal";

// custom styles
import styles from "./index.module.scss";

export const AdminScreen = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [redirect, setRedirect] = useState("");

	const [getBrokerList, { isLoading: gettingBrokerList }] =
		useGetBrokerListMutation();
	const [importTpoUser, { isLoading: updatingDatabase }] =
		useImportTpoUserMutation();
	const [checkUser, { isLoading: checkingUser }] = useCheckUserMutation();
	const [resetPassword, { isLoading: updatingPassword }] =
		useResetPasswordMutation();
	const [
		resetOrganizationUsersPassword,
		{ isLoading: updatingOrganizationPassword },
	] = useResetOrganizationUsersPasswordMutation();

	const [successModalText, setSuccessModalText] = useState("");
	const [successModalVisible, setSuccessModalVisible] = useState(false);

	const handleOpenSuccessModal = () => {
		setSuccessModalVisible(true);
	};

	const handleCloseSuccessModal = () => {
		setSuccessModalVisible(false);
		if (redirect === "admin") return;
		navigate("/pipeline");
	};

	const [errorModalText, setErrorModalText] = useState("");
	const [errorModalVisible, setErrorModalVisible] = useState(false);

	const handleOpenErrorModal = () => {
		setErrorModalVisible(true);
	};

	const handleCloseErrorModal = () => {
		setErrorModalVisible(false);
	};

	const adminColumns = [
		{ id: "", label: "", minWidth: 50 },
		{ id: "name", label: "Name", minWidth: 300 },
		{ id: "nmls-id", label: "NMLS ID", minWidth: 180 },
		{ id: "organization-name", label: "Organization Name", minWidth: 325 },
		{ id: "email", label: "Email", minWidth: 250 },
		{ id: "phone", label: "Phone Number", minWidth: 200 },
		{ id: "access-level", label: "Access Level", minWidth: 250 },
		{ id: "login-enabled", label: "Login Enabled", minWidth: 250 },
	];

	const [modalError, setModalError] = useState("");

	const [orgName, setOrgName] = useState("");
	const [email, setEmail] = useState("");
	const [username, setUsername] = useState("");
	const [loanChannel, setLoanChannel] = useState("");
	const [accessLevel, setAccessLevel] = useState("");
	const [filteredLoanChannel, setFilteredLoanChannel] = useState("");

	const [organizationUsers, setOrganizationUsers] = useState("");
	const [filteredOrganizationUsers, setFilteredOrganizationUsers] =
		useState("");
	const [selectedBroker, setSelectedBroker] = useState(null);

	const [confirmModalText, setConfirmModalText] = useState("");
	const [confirmModalVisible, setConfirmModalVisible] = useState(false);
	const handleOpenConfirmModal = () => {
		setConfirmModalVisible(true);
	};
	const handleCloseConfirmModal = () => {
		setConfirmModalVisible(false);
	};

	const handleSubmitConfirmModal = async () => {
		console.log("135 organization name:", orgName);
		handleCloseConfirmModal();

		try {
			var formData = new FormData();
			formData.append("org_name", orgName);

			const resetOrgPassword = await resetOrganizationUsersPassword({
				formData,
			}).unwrap();

			console.log("140 reset organization users password:", resetOrgPassword);

			setRedirect("admin");
			setSuccessModalText(
				"Successfully sent reset password email to all users within this organization"
			);
			handleOpenSuccessModal();
		} catch (err) {
			setErrorModalText(err.data.message);
			handleOpenErrorModal();
		}
	};

	const [drawerType, setDrawerType] = useState("");
	const [drawerOpen, setDrawerOpen] = useState({});

	const handleOpenDrawer = () => {
		setDrawerOpen({
			right: true,
		});
	};

	const handleCloseDrawer = () => {
		setDrawerOpen({
			right: false,
		});
	};

	const toggleDrawer = (event, drawerType) => {
		if (drawerType !== undefined && drawerType !== "") {
			setDrawerType(drawerType);
			handleOpenDrawer();
		} else {
			setDrawerType("");
			handleCloseDrawer();
		}
	};

	const [processingText, setProcessingText] = useState("");
	const [processingModalVisible, setProcessingModalVisible] = useState(false);

	const handleOpenProcessingModal = () => {
		setProcessingModalVisible(true);
	};

	const handleCloseProcessingModal = () => {
		setProcessingModalVisible(false);
	};

	const [updatePasswordVisible, setUpdatePasswordVisible] = useState(false);

	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");

	const handleOpenUpdatePassword = () => {
		setUpdatePasswordVisible(true);
	};

	const handleCloseUpdatePassword = () => {
		setModalError("");
		setPassword("");
		setConfirmPassword("");
		setUpdatePasswordVisible(false);
	};

	const handleDatabaseClick = async () => {
		const updateDatabase = await importTpoUser({}).unwrap();
		console.log("45 update db:", updateDatabase);
	};

	const handleOrganizationPasswordClick = () => {
		console.log("15 selected orgName!!!", orgName);

		setConfirmModalText(
			"Resetting passwords for all users within this organization. Would you like to proceed?"
		);
		handleOpenConfirmModal();
	};

	const handlePasswordClick = () => {
		console.log("15 update clicked!!!", email);
		if (email !== "") {
			handleOpenUpdatePassword();
		} else {
			setErrorModalText("Enter broker email address.");
			handleOpenErrorModal();
		}
	};

	const handleSearchClick = async () => {
		const searchResults = await getBrokerList({
			email,
			companyName: orgName,
		}).unwrap();
		dispatch(setBrokerListReducer(searchResults));
		setOrganizationUsers(searchResults);

		// remove filters if searched again
		setSelectedBroker(null);
		setFilteredLoanChannel("");
		setFilteredOrganizationUsers("");
	};

	const handleFilterClick = async () => {
		setSelectedBroker(null);
		if (filteredLoanChannel) {
			const filteredOrgUsers = organizationUsers.filter(
				(user) => user.organization.loan_channel === filteredLoanChannel
			);
			setFilteredOrganizationUsers(filteredOrgUsers);
		}
	};

	const handleSignInClick = async () => {
		console.log("210 org name:", orgName);

		const regex = /\(([^)]+)\)/;
		const match = orgName.match(regex);
		const valueInParentheses = match[1]?.toUpperCase();

		if (email !== "" && valueInParentheses !== "") {
			try {
				var formData = new FormData();
				formData.append("email", email);
				formData.append("loan_channel", valueInParentheses);

				const checkUserExists = await checkUser({ formData }).unwrap();

				console.log("140 check user exists:", checkUserExists);
				setSuccessModalText(
					`Successfully signed in as ${email} (${
						loanChannel?.length > 0 ? loanChannel : ""
					}) ${accessLevel === "TPO Secondary" ? accessLevel : ""}.`
				);
				setRedirect("pipeline");
				handleOpenSuccessModal();

				dispatch(
					setImpersonatedUserReducer({
						email,
						loan_channel: loanChannel,
						access_level: accessLevel,
					})
				);
				dispatch(setOrganizationName(checkUserExists.organization));
			} catch (err) {
				setErrorModalText(err.data.message);
				handleOpenErrorModal();
			}
		} else {
			setErrorModalText("Enter broker email address.");
			handleOpenErrorModal();
		}
	};

	const handleChangePassword = async () => {
		if (password === "") {
			setModalError("Password cannot be empty.");
		} else if (password === confirmPassword) {
			if (password?.length < 8) {
				setModalError("Enter a stronger password.");
			} else {
				// change password api
				try {
					const updatePassword = await resetPassword({
						username,
						password,
					}).unwrap();

					console.log("150 updatePassword:", updatePassword);
					if (updatePassword.message.includes("success")) {
						setPassword("");
						setConfirmPassword("");

						handleCloseUpdatePassword();
						setSuccessModalText("Password updated successfully.");
						setRedirect("pipeline");
						handleOpenSuccessModal();
					}
				} catch (err) {
					console.log("150 err:", err);
					if (err.data.message.toLowerCase().includes("user match")) {
						setModalError("Incorrect email. User does not exist.");
					} else {
						setModalError(err.data.message);
					}
				}
			}
		} else {
			setModalError("Passwords do not match.");
		}
	};

	const handleChangeSelectedBroker = (value) => {
		console.log("180 value:", value);

		setSelectedBroker(value);
		const selectedOrganizationUsers =
			filteredOrganizationUsers || organizationUsers || [];

		setOrgName(selectedOrganizationUsers[value]?.organization?.name);
		setEmail(selectedOrganizationUsers[value]?.user?.email);
		setLoanChannel(
			selectedOrganizationUsers[value]?.organization?.loan_channel || ""
		);
		setAccessLevel(selectedOrganizationUsers[value]?.access_level || "");
		setUsername(selectedOrganizationUsers[value]?.user?.username);
	};

	const breadcrumbData = [
		{
			label: "Home",
			path: "/home",
		},
		{
			label: "Admin",
			path: "/admin",
		},
	];

	return (
		<Box>
			<CustomLayout breadcrumb={breadcrumbData} disabledOverflow={true}>
				<Stack
					direction="column"
					className={styles.stackContainer}
					sx={{
						overflow: "hidden",
					}}
				>
					<Stack direction="row" className={styles.addUserContainer}>
						<Stack direction="column">
							<Heading5 text="Manage new AE/AM" fontType="semibold" />
							<Body3 text="Add or remove Account Executives or Account Managers to the portal" />
						</Stack>
						<Stack direction="row" className={styles.manageUserBtnContainer}>
							<SecondaryButton
								text="Remove new AE/AM"
								startIcon={
									<PersonRemoveOutlined className={styles.removeUserIcon} />
								}
								extraClass={styles.removeBtn}
								extraTextClass={styles.removeBtnText}
								onClick={(e) => toggleDrawer(e, "remove-user")}
							/>
							<PrimaryButton
								text="Add new AE/AM"
								startIcon={
									<PersonAddAltOutlined className={styles.addUserIcon} />
								}
								onClick={(e) => toggleDrawer(e, "add-user")}
							/>
						</Stack>
					</Stack>

					<Stack direction="column" className={styles.bodyContainer}>
						<Stack direction="row" className={styles.bodyTopContainer}>
							<Stack direction="column" className={styles.bodyHeaderContainer}>
								<Heading4 text="Broker Search and Login" fontType="semibold" />
								<Body3 text="Find the broker’s credentials by typing the organization’s name or their Email ID or both" />
							</Stack>

							<SecondaryButton
								extraClass={styles.databaseBtn}
								onClick={handleDatabaseClick}
								text="Update Database"
								fontType="semibold"
								startIcon={<SyncOutlined className={styles.databaseIcon} />}
							/>
						</Stack>

						<Stack direction="row" className={styles.searchContainer}>
							<Stack direction="row" className={styles.searchInputContainer}>
								<Stack direction="column" className={styles.inputContainer}>
									<InputLabel htmlFor="input-org-name">
										<Heading6
											text="Enter Organization Name"
											fontType="semibold"
											extraClass={styles.inputHeader}
										/>
									</InputLabel>

									<OutlinedTextInput
										id="input-org-name"
										placeholder="Type Name"
										fullWidth={true}
										value={orgName}
										handleChange={(event) => setOrgName(event.target.value)}
									/>
								</Stack>

								<Stack direction="row" className={styles.orContainer}>
									<Heading6 text="(or)" fontType="semibold" />
								</Stack>

								<Stack direction="column" className={styles.inputContainer}>
									<InputLabel htmlFor="input-email">
										<Heading6
											text="Enter Email ID"
											fontType="semibold"
											extraClass={styles.inputHeader}
										/>
									</InputLabel>

									<OutlinedTextInput
										id="input-email"
										placeholder="abc@lending.com"
										fullWidth={true}
										value={email}
										handleChange={(event) => setEmail(event.target.value)}
									/>
								</Stack>
							</Stack>

							<Stack direction="row" className={styles.searchBtnContainer}>
								<PrimaryButton
									extraClass={styles.searchBtn}
									onClick={handleSearchClick}
									text="Search"
									fontType="semibold"
									startIcon={<SearchOutlined className={styles.searchIcon} />}
								/>
							</Stack>
						</Stack>

						{organizationUsers?.length > 0 ? (
							<Stack direction="row" className={styles.searchContainer}>
								<Stack direction="row" className={styles.searchInputContainer}>
									<Stack direction="column" className={styles.inputContainer}>
										<InputLabel htmlFor="input-org-name">
											<Heading6
												text="Loan Channel"
												fontType="semibold"
												extraClass={styles.inputHeader}
											/>
										</InputLabel>

										<SecondarySelect
											id={`outlined-input-loan-channel`}
											placeholder="Select"
											displayEmpty={true}
											fullWidth={true}
											value={filteredLoanChannel}
											handleChange={(event) => {
												setFilteredLoanChannel(event.target.value);
											}}
											options={loanChannelOptions}
											disabled={false}
											error={false}
										/>
									</Stack>
								</Stack>

								<Stack direction="row" className={styles.searchBtnContainer}>
									<PrimaryButton
										extraClass={styles.searchBtn}
										onClick={handleFilterClick}
										text="Filter"
										fontType="semibold"
										startIcon={<FilterAlt className={styles.searchIcon} />}
									/>
								</Stack>
							</Stack>
						) : null}

						<Stack className={styles.tableContainer}>
							<CustomTable
								columnData={adminColumns}
								rowData={filteredOrganizationUsers || organizationUsers || []}
								tableType="admin-users"
								selectedBroker={selectedBroker}
								handleSelectBroker={handleChangeSelectedBroker}
							/>
						</Stack>

						<Stack direction="row" className={styles.footerContainer}>
							<SecondaryButton
								extraClass={styles.footerBtn}
								onClick={handleOrganizationPasswordClick}
								text="Set Password for Organization"
								disabled={orgName === ""}
							/>

							<SecondaryButton
								extraClass={styles.footerBtn}
								onClick={handlePasswordClick}
								text="Update Password"
								disabled={email === ""}
							/>

							<PrimaryButton
								extraClass={styles.footerBtn}
								onClick={handleSignInClick}
								text="Sign in as this broker"
								disabled={email === ""}
							/>
						</Stack>
					</Stack>
				</Stack>
			</CustomLayout>

			{/* modals */}
			<LoaderModal
				open={
					updatingDatabase ||
					updatingPassword ||
					gettingBrokerList ||
					checkingUser ||
					updatingOrganizationPassword
				}
			/>
			<ProcessingModal open={processingModalVisible} text={processingText} />
			<SuccessModal
				open={successModalVisible}
				handleClose={handleCloseSuccessModal}
				text={successModalText}
			/>
			<ErrorModal
				open={errorModalVisible}
				handleClose={handleCloseErrorModal}
				text={errorModalText}
			/>
			<UpdatePasswordModal
				open={updatePasswordVisible}
				password={password}
				setPassword={setPassword}
				confirmPassword={confirmPassword}
				setConfirmPassword={setConfirmPassword}
				modalError={modalError}
				handleClose={handleCloseUpdatePassword}
				handleSubmit={handleChangePassword}
			/>
			<ConfirmationModal
				open={confirmModalVisible}
				text={confirmModalText}
				confirmText="Confirm"
				handleConfirm={handleSubmitConfirmModal}
				closeText="Close"
				handleClose={handleCloseConfirmModal}
			/>
			<TemporaryDrawer
				// drawer props
				drawerOpen={drawerOpen}
				drawerDirection="right"
				drawerType={drawerType}
				toggleDrawer={toggleDrawer}
				handleCloseDrawer={handleCloseDrawer}
				handleOpenProcessingModal={handleOpenProcessingModal}
				handleCloseProcessingModal={handleCloseProcessingModal}
				setProcessingText={setProcessingText}
				handleOpenSuccessModal={handleOpenSuccessModal}
				setSuccessText={setSuccessModalText}
				handleOpenErrorModal={handleOpenErrorModal}
				setErrorText={setErrorModalText}
				// custom props
				setRedirect={setRedirect}
			/>
		</Box>
	);
};

export default AdminScreen;
