export const LOAN_CHANNEL_CONSTANTS = {
	CB: "CB",
	NDC: "NDC",
	CORR: "CORR",
	WHS: "WHS",
};

export const TPO_SECONDARY = "TPO Secondary";

export const BROKER_ADMIN = "broker admin";

export const LAYOUT_CONFIRM_MODAL_TITLE_CONSTANTS = {
	SIGN_IN: "Sign in as a broker to gain access.",
	SWITCH_ORG: "Are you sure you want to switch organization?",
};
