import React from "react";
import { DateTime } from "luxon";

// mui components
import { Box, Stack, TableCell, TableRow } from "@mui/material";

// custom components
import Body3 from "../../typography/body-03";

// mui icons
import {
	FileUploadOutlined,
	ExpandLessOutlined,
	ExpandMoreOutlined,
} from "@mui/icons-material";

// custom styles
import styles from "../index.module.scss";

const ConditionTrackingRow = ({
	row,
	handleUploadClick,
	handleDownloadClick,
	minimizedRow,
	handleAddMinimizeRow,
	handleDeleteMinimizeRow,
}) => {
	return (
		<TableRow hover tabIndex={-1} key={row.label}>
			<TableCell>
				<Body3 text={row.status} extraClass={styles.greenText} />
			</TableCell>
			<TableCell>
				<Body3 text={row.sub_status} extraClass={styles.greenText} />
			</TableCell>
			<TableCell>
				<Body3 text={row.category} extraClass={styles.greenText} />
			</TableCell>
			<TableCell>
				<Stack direction="row" className={styles.overflowTextContainer}>
					<Body3
						text={row.description}
						extraClass={
							minimizedRow?.includes(row?.id)
								? styles.truncatedText
								: styles.truncatedText + " " + styles.expandedText
						}
					/>
					{row.description !== "" &&
						(minimizedRow?.includes(row?.id) ? (
							<ExpandMoreOutlined
								onClick={() => handleDeleteMinimizeRow(row?.id)}
								className={styles.truncatedIcon}
							/>
						) : (
							<ExpandLessOutlined
								onClick={() => handleAddMinimizeRow(row?.id)}
								className={styles.truncatedIcon}
							/>
						))}
				</Stack>
			</TableCell>
			<TableCell
				onClick={(event) => handleUploadClick(event, row.id)}
				className={styles.textCenter + " " + styles.minimizedWidth}
			>
				<Box className={styles.uploadIconContainer}>
					<FileUploadOutlined className={styles.uploadIcon} />
				</Box>
			</TableCell>
			<TableCell
				onClick={(event) =>
					handleDownloadClick(event, row.recently_uploaded_attachment_ids)
				}
				className={styles.minimizedWidth}
			>
				<Body3
					text={
						row?.attachment_upload_timestamp !== undefined
							? DateTime.fromISO(row?.attachment_upload_timestamp, {
									zone: "America/New_York",
							  }).toFormat("MM/dd/yyyy hh:mm a")
							: "NA"
					}
					fontType="semibold"
					extraClass={
						row?.attachment_upload_timestamp !== undefined
							? styles.conditionTimestampLink
							: styles.conditionTimestamp
					}
				/>
			</TableCell>
		</TableRow>
	);
};

export default ConditionTrackingRow;
